import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { device } from '../../GlobalStyles/helpers/device'
import { NavButton } from '../../atoms/ui/buttons'
import { PhoneEmail } from '../../molecules/contactInfo/PhoneEmail'
import { SocialMedia } from '../../molecules/contactInfo/SocialMedia'
import { SiteMap } from './SiteMap'
import { Copy } from './Copy'

const Container = styled.footer`
  padding-top: 70px;

  div {
    margin-bottom: 20px;
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media ${device.tablet} {
    width: 90%;
    margin: 0 auto;
    flex-direction: row;
    justify-content: space-between;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const links = [
  {
    to: '/',
    value: 'Home',
  },
  {
    to: '/blog/01-2021/about-us/',
    value: 'About',
  },
  {
    to: '/blog',
    value: 'Blog',
  },
  {
    to: '/fleet',
    value: 'Fleet',
  },
  {
    to: '/services',
    value: 'Services',
  },
  {
    to: '/partners',
    value: 'Partners',
  },
  {
    to: '/resources',
    value: 'Library',
  },
  {
    to: '/reviews',
    value: 'Reviews',
  },
  {
    to: '/contact-us',
    value: 'Contact',
  },
  //{
  //to: '/careers',
  //value: 'Careers',
  //},
]

export const Footer = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        contactInfo {
          phoneNumber
          email
          socialMedia {
            platform
            url
          }
        }
      }
    }
  `)

  const { phoneNumber, email, socialMedia } = data.sanitySiteSettings.contactInfo

  return (
    <Container>
      <Flex>
        <SiteMap className="site-map" links={links} />
        <SocialMedia horizontal className="social-media" socialMedia={socialMedia} />
        <Column>
          <NavButton className="button" to="/contact-us">
            Learn To Fly!
          </NavButton>
          <PhoneEmail className="phone-email" phoneNumber={phoneNumber} email={email} />
        </Column>
      </Flex>
      <Copy />
    </Container>
  )
}
