import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { GlobalStyle } from '../GlobalStyles/GlobalStyle'
import { Normalize } from '../GlobalStyles/normalize'
import { Footer } from './Footer/Footer'
import { Header } from './Header/Header'

const LayoutWrapper = styled.main`
  padding-top: 80px;
  padding-bottom: 20px;
`

interface Props {
  children: ReactElement | ReactElement[]
}

export const Layout = (props: Props): JSX.Element => (
  <>
    <Normalize />
    <GlobalStyle />

    <Header />
    <LayoutWrapper>{props.children}</LayoutWrapper>
    <Footer />
  </>
)
