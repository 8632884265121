import React from 'react'
import styled from 'styled-components'
import { InLink } from '../../atoms/ui/links'
import { colors } from '../../GlobalStyles/vars/colors'

const Container = styled(InLink)`
  display: flex;
  align-items: center;
  text-decoration: none;

  :hover {
    color: ${colors.grey};
  }
`

const Img = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: ${colors.white} 2px solid;
  margin: 10px;
`

const Span = styled.span`
  display: inline-block;
`

interface Props {
  className?: string
  title: string
  src: string
  alt: string
}

export const Logo = ({ className, title, src, alt }: Props): JSX.Element => {
  return (
    <Container className={className} to="/">
      <Img alt={alt} src={src} />
      <Span>{title}</Span>
    </Container>
  )
}
