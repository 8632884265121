import React from 'react'

export const LocationIcon = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220.79 220.79" width="40" height="40">
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path d="M181.89 38.9a101.11 101.11 0 1029.62 71.5 100.76 100.76 0 00-29.62-71.5M110.4 0a110.38 110.38 0 11-78.07 32.33A110.09 110.09 0 01110.4 0z" />
          <path
            d="M110.4 62.58A28.52 28.52 0 1181.88 91.1a28.52 28.52 0 0128.52-28.52m-.22 117.51L155.83 113A50.4 50.4 0 1065 113z"
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
