import React from 'react'

export const PhoneIcon = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220.79 220.79" width="40" height="40">
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path d="M181.9 38.9a101.13 101.13 0 1029.61 71.5 100.8 100.8 0 00-29.61-71.5M110.4 0a110.38 110.38 0 11-78.07 32.33A110.09 110.09 0 01110.4 0z" />
          <path
            d="M68.14 122.19c16.28 19.95 39.2 40.87 65.43 46.76 13.42 3 23.25 1 33-8.84 3.76-3.78 5.58-9.4 2-13.88l-17.9-17.9c-3.86-3.86-8.34-6.48-14.26-2.13-3.68 2.7-6.74 7.5-10.24 10-9.82-3.87-19.46-12.52-27-19.91-4-3.88-12.42-16.75-14.41-21.67 4.65-6.62 13.79-9.75 11.85-18.54-.81-3.71-20.54-23.57-25.46-25.46-4.91-.32-6.72.55-9.32 3.13-21 20.85-10 48.44 6.31 68.44"
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
