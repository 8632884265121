import styled from 'styled-components'
import { colors } from '../../GlobalStyles/vars/colors'
import { Link } from 'gatsby'

const style = `
  color: inherit;
  text-decoration: underline;
  cursor: pointer;

  :hover,
  :focus {
    color: ${colors.primary};
  }

  :active {
    color: ${colors.lightPrimary};
  }
`

export const A = styled.a`
  ${style}
`

export const InLink = styled(Link)`
  ${style}
`
