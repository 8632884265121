import { createGlobalStyle } from 'styled-components'
import { colors } from './vars/colors'
import { fontSizes } from './vars/fontSizes'

export const GlobalStyle = createGlobalStyle`

  html {
    font-size: 110%;
  }

  body {
    font-family: 'Helvetica';
    font-weight: 400;
    line-height: 1.75;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
  color: ${colors.primary};
  padding: 0 5%;
  margin: 3rem 0 1.38rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 1.3;
  }

  h1 {
    font-size: ${fontSizes.x2l};
    margin-top: 15px;
  }

  h2 {
    font-size: ${fontSizes.xl};
  }

  h3 {
    font-size: ${fontSizes.lg};
  }

  h4 {
    font-size: ${fontSizes.md};
  }

  h5 {
    font-size: ${fontSizes.sm};
  }

  small,
  .text_small {
    font-size: ${fontSizes.xs};
  }

  p {
    padding: 0 2%;
    margin-bottom: 1rem;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`
