import React from 'react'

//project icons
import { FleetIcon } from './Icons/FleetIcon'
import { ServiceIcon } from './Icons/ServiceIcon'
import { PartnerIcon } from './Icons/PartnerIcon'

//util/ui icons
import { HamburgerIcon } from './Icons/hamburger'
import { EmailIcon } from './Icons/EmailIcon'
import { PhoneIcon } from './Icons/PhoneIcon'
import { WebIcon } from './Icons/WebIcon'
import { LocationIcon } from './Icons/LocationIcon'

//social media
import { TwitterIcon } from './Icons/twitter'
import { LinkedInIcon } from './Icons/linkedin'
import { InstagramIcon } from './Icons/instagram'
import { FacebookIcon } from './Icons/facebook'

export function Icon(props: Props): JSX.Element {
  switch (props.symbol) {
    //project
    case 'fleet':
      return <FleetIcon />
    case 'service':
      return <ServiceIcon />
    case 'partner':
      return <PartnerIcon />

    //ui
    case 'hamburger':
      return <HamburgerIcon />
    case 'email':
      return <EmailIcon />
    case 'phone':
      return <PhoneIcon />
    case 'web':
      return <WebIcon />
    case 'location':
      return <LocationIcon />

    //social media
    case 'linkedin':
      return <LinkedInIcon />
    case 'twitter':
      return <TwitterIcon />
    case 'facebook':
      return <FacebookIcon />
    case 'instagram':
      return <InstagramIcon />

    //error on default
    default:
      return <span>Can&apos;t find icon: {props.symbol}</span>
  }
}

//type check for a symbol
interface Props {
  symbol: string
}
