/*
  Takes an email address and or phone number as strings and displays them with working links and icons.
*/
import React from 'react'
import styled from 'styled-components'
import { IconItem } from '../IconItem' //required to render the icon

const Container = styled.div`
  width: fit-content; //keep width under control
`

interface Props {
  className?: string

  //either is optional to be able to render only one
  phoneNumber?: string
  email?: string
}

export const PhoneEmail = ({ phoneNumber, email, className }: Props): JSX.Element => {
  return (
    <Container className={className}>
      {phoneNumber && <IconItem symbol="phone" span={phoneNumber} link={'tel:' + phoneNumber.replace(/\D/g, '')} />}
      {email && <IconItem symbol="email" span={email} link={'mailto:' + email} />}
    </Container>
  )
}
