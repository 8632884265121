import React, { useState } from 'react'
import styled from 'styled-components'
import { device } from '../../../GlobalStyles/helpers/device'
import { colors } from '../../../GlobalStyles/vars/colors'
import { Icon } from '../../../Icon/Icon'
import { NavLink } from './NavLink'

const Container = styled.div`
  .hide-nav {
    height: 0;
  }
`

const linkHeight = 62

const Nav = styled.nav`
  background-color: ${colors.brightWhite};
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 10px;
  overflow: hidden;
  height: ${(props: { numbLinks: number }) => props.numbLinks * linkHeight}px;
  transition: height 0.15s ease-out;

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media ${device.laptop} {
    overflow: visible;
    position: relative;
    margin-top: -5px;

    ul {
      flex-direction: row;

      a {
        width: 100px;
      }
    }
  }
`

const Button = styled.button`
  background-color: inherit;
  border-style: none;
  color: ${colors.white};

  @media ${device.laptop} {
    display: none;
  }
`

interface Props {
  className?: string
  links: Array<Link>
}

interface Link {
  to: string
  value: string
}

const Hamburger = ({ onClick }: { onClick: VoidFunction }): JSX.Element => (
  <Button onClick={onClick}>
    <Icon symbol="hamburger" />
  </Button>
)

export const NavBar = ({ className, links }: Props): JSX.Element => {
  const [navOpen, setNavOpen] = useState(false)

  return (
    <Container className={className}>
      <Hamburger
        onClick={(): void => {
          setNavOpen(!navOpen)
        }}
      />
      <Nav numbLinks={links.length} className={navOpen ? '' : 'hide-nav'}>
        <ul>
          {links.map((link) => {
            return <NavLink key={link.to + 'nvb'} to={link.to} value={link.value} />
          })}
        </ul>
      </Nav>
    </Container>
  )
}
