import { lightenDarkenColor } from '../helpers/lightenDarkenColor' //first arg is color, second is amount where positive is lighter.

const lighten = 23

export const colors = {
  //White and Black
  pureBlack: '#0c0000',
  black: '#211f20',
  grey: '#e6edeb',
  white: '#f7f7ff',
  brightWhite: '#ffffff',

  //Approve and Danger
  danger: '#a23221',
  lightDanger: lightenDarkenColor('#a23221', lighten),
  approve: '#0c7952',
  lightApprove: lightenDarkenColor('#0c7952', lighten),

  //Brand Colors
  primary: '#35439b',
  lightPrimary: '#4b5aa8',
}
