import React from 'react'

export const FacebookIcon = (): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 1024 1024"
      version="1.1"
      viewBox="0 0 1024 1024"
      xmlSpace="preserve"
      width="40"
      height="40"
    >
      <path
        d="M1024 512C1024 229.2 794.8 0 512 0S0 229.2 0 512c0 255.6 187.2 467.4 432 505.8V660H302V512h130V399.2C432 270.9 508.4 200 625.4 200c56 0 114.6 10 114.6 10v126h-64.6c-63.6 0-83.4 39.5-83.4 80v96h142l-22.7 148H592v357.8c244.8-38.4 432-250.2 432-505.8z"
        className="st0"
      />
    </svg>
  )
}
