import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { NavButton } from '../../atoms/ui/buttons'
import { PhoneEmail } from '../../molecules/contactInfo/PhoneEmail'
import { colors } from '../../GlobalStyles/vars/colors'
import { Logo } from './Logo'
import { NavBar } from './NavBar/NavBar'

const Container = styled.header`
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 80px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.primary};
  color: ${colors.white};

  .button,
  .phone {
    display: none;
  }

  .nav {
    align-self: flex-start;
    padding-top: 14px;
  }
`

const links = [
  {
    to: '/services',
    value: 'Services',
    nodes: [
      {
        to: '/one',
        value: 'One',
      },
    ],
  },
  {
    to: '/fleet',
    value: 'Fleet',
  },
  {
    to: '/partners',
    value: 'Partners',
  },
  {
    to: '/blog/01-2021/about-us/',
    value: 'About',
  },
  {
    to: '/contact-us',
    value: 'Contact',
  },
]

export const Header = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        title
        logo {
          alt
          asset {
            fixed {
              src
            }
          }
        }
        contactInfo {
          phoneNumber
        }
      }
    }
  `)

  const site = data.sanitySiteSettings
  const { phoneNumber } = site.contactInfo
  const { logo } = site

  return (
    <Container>
      <Logo title={site.title} alt={logo.alt} src={logo.asset.fixed.src} />
      <NavButton className="button" to="/contact-us">
        Learn to Fly!
      </NavButton>
      <PhoneEmail className="phone" phoneNumber={phoneNumber} />
      <NavBar className="nav" links={links} />
    </Container>
  )
}
