import React from 'react'
import styled from 'styled-components'
import { NavButton } from '../../../atoms/ui/buttons'

const Container = styled.li`
  text-align: center;
  margin-top: 8px;

  a {
    width: 300px;
  }
`

interface Props {
  className?: string
  to: string
  value: string
}

export const NavLink = ({ to, value, className }: Props): JSX.Element => {
  return (
    <Container className={className}>
      <NavButton to={to}>{value}</NavButton>
    </Container>
  )
}
