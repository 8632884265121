import React from 'react'

export const TwitterIcon = (): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      width="40"
      height="40"
      viewBox="0 0 512 512"
    >
      <path d="M256 0c141.29 0 256 114.71 256 256 0 141.29-114.71 256-256 256C114.71 512 0 397.29 0 256 0 114.71 114.71 0 256 0zm-45.091 392.158c113.283 0 175.224-93.87 175.224-175.223 0-2.682 0-5.364-.128-7.919a126.156 126.156 0 0030.779-31.928c-10.983 4.853-22.861 8.174-35.377 9.706 12.772-7.663 22.478-19.668 27.076-34.099a124.727 124.727 0 01-39.081 14.942c-11.239-12.005-27.203-19.412-44.955-19.412-33.972 0-61.558 27.586-61.558 61.558 0 4.853.511 9.578 1.66 14.048-51.213-2.554-96.552-27.075-126.947-64.368a61.772 61.772 0 00-8.302 30.907 61.308 61.308 0 0027.459 51.213c-10.09-.255-19.541-3.065-27.842-7.662v.766c0 29.885 21.2 54.661 49.425 60.409a61.42 61.42 0 01-16.219 2.171c-3.96 0-7.791-.383-11.622-1.15 7.79 24.521 30.523 42.274 57.471 42.784-21.073 16.476-47.637 26.31-76.501 26.31-4.981 0-9.834-.256-14.687-.894 26.948 17.624 59.387 27.841 94.125 27.841z" />
    </svg>
  )
}
