import React from 'react'

export const PartnerIcon = (): JSX.Element => {
  return (
    <svg id="prefix__Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <path
        className="prefix__cls-1"
        d="M98.52 301.15a21.2 21.2 0 00-33.48-26L43.45 303a21.18 21.18 0 0033.45 26zM163.83 301.29a21.19 21.19 0 00-33.45-26l-46 59.13A21.16 21.16 0 00101 368.56a23 23 0 002.73-.17 21 21 0 0014.11-8l46-59.12zM204.8 331.94l.42-.56a21.18 21.18 0 00-35.05-23.62L135.77 352a21.19 21.19 0 0033.45 26l8.3-10.7.21-.32a27.61 27.61 0 012.17-3.22l23.51-30.29c.46-.47.91-1.02 1.39-1.53zM313.83 383.49c.1-2.92-4.19-5.16-5.09-5.62l-51.12-26.2-.55 4.19a30.24 30.24 0 01-6.07 14.59l-9.45 12.19 3.81 1.41c25.4 9.38 41.39 12.48 51.44 12.48 7.15 0 11.29-1.57 13.66-3.57 2.65-2.22 3.85-4.39 3.37-9.47zM247.32 345.25l-.18-.52-.09-.25-.1-.28a21 21 0 00-19.66-13.4 21.08 21.08 0 00-14.87 6l-.71 1c-.32.48-.63 1-1 1.45L185.19 372l-.29.46a21.52 21.52 0 00-2.25 12.46 21.06 21.06 0 0021 18.52 21.7 21.7 0 002.66-.16 21.19 21.19 0 0014.1-8L244 365a21 21 0 003.32-19.75zM462.19 115.18a5.95 5.95 0 00-7.73-4.09l-6.75 2.38c-29.81 8.25-52.59 1-85.84-6-27-5.74-52.51-11.17-73.9-7-10.76 2.09-68.48 23-81.94 51.49-8.32 17.56-8.54 23.16-8.8 29.73a64.67 64.67 0 01-1.69 14.06l-.33.9c-5.2 11.43-10.47 26.76-10.28 32.64v1.6l1.37.84c3.78 2.32 15.47 5.6 27.4 1 18.05-7 28.86-28.51 31.27-62.09a4.45 4.45 0 118.88.65 156.26 156.26 0 01-2.17 17.71l-1 5.44 5.09-2.17c9.92-4.22 24.27-11.63 30.84-16.38l.84-.61a4.46 4.46 0 014.33-.66c29.35 11.31 124.4 67.23 149.83 101.25a3 3 0 002.06 1.18 96.54 96.54 0 0010.63.64c12.73 0 20-3.37 31.35-8.63l1-.44a64.26 64.26 0 019.5-4.18 5.94 5.94 0 003.7-7.11z"
      />
      <path
        className="prefix__cls-1"
        d="M437.05 284.5l-.37-.55a4.34 4.34 0 01-.71-1.21l-.26-.49C419 256.87 330.37 200.36 292.24 184.4l-1.44-.61-1.32.85c-9.28 6-28.9 16.34-40.13 19.31l-1.52.41-.52 1.48c-9.49 27-26.25 35.27-38.63 37.43-13.91 2.4-28.73-2.15-31.65-8.73-2.75-6.23.82-21.15 9.77-40.94l.2-.6a58.72 58.72 0 001.35-11.72c.26-7.15.5-13.9 9.59-33.18 10.07-21.32 40.92-37.73 58.4-45.61l10.48-4.72-11.45-1c-19.75-1.65-35.31 3.67-66.05 16.21-27.48 11.14-56.06 17-82.67 17-17.11 0-27.08-.59-41.44-5.31h-.1l-3.34-1a6 6 0 00-7.3 3.5L.41 264.67a5.94 5.94 0 004.11 7.95L6 273c12.18 2.81 19.64 7.06 33.59 16l2.29 1.46L58 269.68a30.11 30.11 0 0142.24-5.31 30.21 30.21 0 0110.16 14.49l1.74 5.33 11.17-14.39a30.08 30.08 0 0153.61 14.76 29.53 29.53 0 01.22 3.85v3.65l3.58-.74A30.07 30.07 0 01217 319.79l.13 3.59 3.49-.78a30 30 0 0134 16.77l.43.94L326.59 377l.71.26a26 26 0 005.71.64c9.65 0 17.51-5.52 20.82-11.95 3-5.77 2.76-13-5.14-17.27l-79.38-45a4.45 4.45 0 011-8.17 4.39 4.39 0 013.4.41l82.23 46.51.6.57h.41l4.25 2.41.45.2a27.71 27.71 0 0030.56-8.37c4.89-6.13 5.44-17.51.9-20.81l-50.37-29.28c-20.39-11.83-39.65-23-40.39-23.46a4.37 4.37 0 01-2.06-2.68 4.45 4.45 0 01.45-3.4 4.4 4.4 0 012.7-2.07 4.53 4.53 0 013.37.43c.35.21 12.48 7.25 27.27 15.82 15.32 8.89 34.32 19.92 49.36 28.67l.43.26c10.26 5.95 18.66 10.85 22.87 13.33 6.64 3.86 15.78 3.18 22.76-1.68 7.55-5.22 10.9-13.79 9.2-23.54a15.39 15.39 0 00-1.65-4.33z"
      />
    </svg>
  )
}
