import styled from 'styled-components'
import { Link } from 'gatsby'
import { colors } from '../../GlobalStyles/vars/colors'

const Button = styled.button`
  color: ${colors.brightWhite};
  background-color: ${colors.danger};

  font: inherit;
  border: none;
  padding: 0.5em 1em;
  margin: 2px 5px;
  border-radius: 3px;
  border-color: currentColor;
  box-shadow: 6px 7px 19px -15px rgba(0, 0, 0, 0.75);

  :hover,
  :active {
    background-color: ${colors.lightDanger};
  }

  :hover {
    color: ${colors.white};
  }

  :focus {
    background-color: ${colors.brightWhite};
    color: ${colors.danger};
  }

  :active {
    color: ${colors.black};
  }
`

export const DangerButton = styled(Button)`
  background-color: ${colors.danger};

  :hover,
  :active {
    background-color: ${colors.lightDanger};
  }

  :focus {
    color: ${colors.danger};
  }
`

export const ApproveButton = styled(Button)`
  background-color: ${colors.approve};

  :hover,
  :active {
    background-color: ${colors.lightApprove};
  }

  :focus {
    color: ${colors.approve};
  }
`

export const SubmitButton = styled(Button)`
  background-color: ${colors.primary};

  :hover,
  :active {
    background-color: ${colors.lightPrimary};
  }

  :focus {
    color: ${colors.primary};
  }
`

export const NavButton = styled(Link)`
  color: ${colors.primary};
  background-color: ${colors.brightWhite};
  text-decoration: none;
  display: block;
  width: fit-content;

  font: inherit;
  border: 2px solid ${colors.primary};
  padding: calc(0.5em - 2px) calc(1em - 2px);
  margin: 2px 5px;
  border-radius: 3px;
  box-shadow: 6px 7px 19px -15px rgba(0, 0, 0, 0.75);

  :hover,
  :active,
  :focus {
    background-color: ${colors.lightPrimary};
    border-color: ${colors.lightPrimary};
  }

  :hover,
  :focus {
    color: ${colors.white};
  }

  :active {
    color: ${colors.black};
  }
`
