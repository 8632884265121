import React from 'react'
import styled from 'styled-components'
import { IconItem } from '../IconItem'

const horizontal = `
  display: flex;
`

const Container = styled.ul.attrs((props: Props) => ({
  horizontal: props.horizontal || false,
  fill: props.fill || 'black',
}))`
  width: fit-content;
  fill: ${(props) => props.fill};

  ${(props) => (props.horizontal ? horizontal : '')};
`

interface Props {
  className?: string
  horizontal?: boolean
  user?: boolean
  fill?: string
  socialMedia: [Social]
}

interface Social {
  platform: string
  url: string
  user: string
  _key?: string
}

const SocialLink = ({ platform, url, user }: Social, showSpan: boolean): JSX.Element => {
  return (
    <li key={platform + user}>
      <IconItem symbol={platform} link={url} span={showSpan ? user : ''} />
    </li>
  )
}

export const SocialMedia = ({ socialMedia, horizontal, user, fill, className }: Props): JSX.Element => {
  return (
    <Container className={className} horizontal={horizontal} fill={fill}>
      {socialMedia && socialMedia.map((social: Social) => SocialLink(social, user || false))}
    </Container>
  )
}
