import React from 'react'

export const EmailIcon = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220.79 220.79" width="40" height="40">
      <defs></defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="M181.9,38.9a101.12,101.12,0,1,0,29.61,71.5A100.8,100.8,0,0,0,181.9,38.9M110.4,0A110.38,110.38,0,1,1,32.33,32.33,110.09,110.09,0,0,1,110.4,0Z" />
          <path d="M109.79,139.25l-16-10.18L58.6,165H162.05l-34.45-34.2L113,139.29l-1.63,1ZM54.35,160.48,88.44,125.7,54.35,104.05Zm78.75-33,33.34,33.11V108Zm-32.31-29.3a7.54,7.54,0,0,0,1.54,5.06,4.56,4.56,0,0,0,3.55,1.81,6,6,0,0,0,2.8-.8,10.24,10.24,0,0,0,2.82-2.36,12.29,12.29,0,0,0,2.19-4,14.18,14.18,0,0,0,.86-4.85,7.25,7.25,0,0,0-1.6-5A5.07,5.07,0,0,0,109,86.31a5.75,5.75,0,0,0-2.87.77,9,9,0,0,0-2.6,2.49,13.55,13.55,0,0,0-2,4.16A15.14,15.14,0,0,0,100.79,98.22Zm11.53,6.42a13.05,13.05,0,0,1-3.47,2.87,7.61,7.61,0,0,1-3.83,1.08,7.79,7.79,0,0,1-4.12-1.23,8.89,8.89,0,0,1-3.24-3.8,12.67,12.67,0,0,1-1.25-5.64,16.52,16.52,0,0,1,1.93-7.57,14.65,14.65,0,0,1,4.81-5.69,10.13,10.13,0,0,1,5.58-1.9,7.73,7.73,0,0,1,4,1.08,9.27,9.27,0,0,1,3.23,3.29l.83-3.69H121l-3.42,16c-.47,2.22-.71,3.44-.71,3.68a1.47,1.47,0,0,0,.49,1.11,1.63,1.63,0,0,0,1.17.46,6.38,6.38,0,0,0,3.31-1.44,13.33,13.33,0,0,0,4.3-5.09,14.71,14.71,0,0,0,1.58-6.58,14.25,14.25,0,0,0-2-7.4,14.49,14.49,0,0,0-6.07-5.5,19.32,19.32,0,0,0-8.91-2.06,20.5,20.5,0,0,0-10.18,2.59,17.85,17.85,0,0,0-7.14,7.46A22,22,0,0,0,90.81,97a19,19,0,0,0,2.53,10,15.09,15.09,0,0,0,7.33,6.21,27.2,27.2,0,0,0,10.62,2,23.28,23.28,0,0,0,10.44-2.1,16.44,16.44,0,0,0,6.3-5.1h4.28a17,17,0,0,1-4.16,5.09,22,22,0,0,1-7,4.1,28.14,28.14,0,0,1-9.79,1.51,33.41,33.41,0,0,1-9.73-1.35,20.62,20.62,0,0,1-7.6-4.07,18.18,18.18,0,0,1-4.73-6.25,23.55,23.55,0,0,1-2-9.68,26.52,26.52,0,0,1,2.37-11,22.21,22.21,0,0,1,8.25-9.84,23.5,23.5,0,0,1,12.95-3.42,23,23,0,0,1,10.6,2.41,17.65,17.65,0,0,1,7.4,7.18,17.87,17.87,0,0,1,2.31,8.92,17.66,17.66,0,0,1-4.84,12.22c-2.89,3.19-6,4.79-9.45,4.79a6,6,0,0,1-2.65-.5,3.14,3.14,0,0,1-1.48-1.44A6,6,0,0,1,112.32,104.64Zm52-2.52-9.41-8.21,0-29V63.7H120.32l-9-7.86L101.17,63.7H66.77V90.33L56.62,98.18l14.79,9.23.18-38.15h78.56l.12,41.06Z" />
        </g>
      </g>
    </svg>
  )
}
