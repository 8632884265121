import React from 'react'
import styled from 'styled-components'
import { A } from '../../atoms/ui/links'
import { colors } from '../../GlobalStyles/vars/colors'

const Container = styled.span`
  font-size: 0.8em;
  text-align: center;
  display: block;
  margin-top: 15px;
  margin-bottom: 10px;
  color: ${colors.lightPrimary};
`

interface Props {
  className?: string
}

export const Copy = ({ className }: Props): JSX.Element => {
  return (
    <Container className={className}>
      <A href="https://deadhead.design">&copy;{new Date().getFullYear()} - Built by DeadheadDesign.Aero</A>
    </Container>
  )
}
