import React from 'react'

export const HamburgerIcon = (): JSX.Element => {
  return (
    <svg
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      width="50px"
      height="50px"
    >
      <path d="M5 7.5H20" stroke="currentColor" />
      <path d="M5 12.5H20" stroke="currentColor" />
      <path d="M5 17.5H20" stroke="currentColor" />
    </svg>
  )
}
