import React from 'react'
import styled from 'styled-components'
import { A } from '../atoms/ui/links'
import { Icon } from '../Icon/Icon'

const Container = styled(A)`
  display: flex;
  align-items: center;
  margin: 5px;
  font-size: 1.3rem;

  svg {
    margin-right: 3px;
  }
`

interface Props {
  link: string
  symbol: string
  span?: React.ReactNode
}

export const IconItem = (props: Props): JSX.Element => {
  return (
    <Container href={props.link}>
      <Icon symbol={props.symbol} />
      {props.span}
    </Container>
  )
}
