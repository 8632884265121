import React from 'react'

export const WebIcon = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220.79 220.79" width="40" height="40">
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path d="M181.9 38.9a101.13 101.13 0 1029.61 71.5 100.8 100.8 0 00-29.61-71.5M110.4 0a110.38 110.38 0 11-78.07 32.33A110.09 110.09 0 01110.4 0z" />
          <path d="M106.62 175.38v-39.3a213 213 0 00-25.26 2.23 84.92 84.92 0 007 19.32c5 9.55 11.35 16 18.3 17.75m32.94-37.58a203.23 203.23 0 00-25.39-1.8v39.38c7-1.74 13.34-8.2 18.3-17.75a85.18 85.18 0 007.05-19.83zm3-27.84h-28.4v18.51a211.15 211.15 0 0126.8 1.92 126.67 126.67 0 001.56-20zm-3.1-27.33a152.56 152.56 0 01-25.3 2.74v17h28.16a117.81 117.81 0 00-2.89-19.74zm-25.3-37.22v32.41a144.28 144.28 0 0023.28-2.49 77.86 77.86 0 00-5-12.16c-4.97-9.56-11.36-16.02-18.31-17.76zM83.38 75.19a143.18 143.18 0 0023.24 2.61V45.41c-7 1.74-13.33 8.2-18.3 17.76a78.45 78.45 0 00-4.94 12.02zm-4.92 27.23h28.16V85.35a149.33 149.33 0 01-25.26-2.87 118.38 118.38 0 00-2.9 19.94zm1.41 28.5a223.63 223.63 0 0126.75-2.38V110h-28.4v.44a125.89 125.89 0 001.65 20.48zm30.53-93.53a73 73 0 11-51.63 21.38 72.77 72.77 0 0151.63-21.38zM89.13 172.32a57.86 57.86 0 01-7.5-11.24 92.87 92.87 0 01-7.72-21.54q-9.81 1.78-19.7 4.46a65.7 65.7 0 0034.92 28.32zM167 143.37q-10-2.71-20-4.43a93.07 93.07 0 01-7.86 22.14 57.41 57.41 0 01-7.5 11.24A65.67 65.67 0 00167 143.37zm8.9-33.41h-25.78v.44a131.81 131.81 0 01-1.67 21.14 215 215 0 0122 5 65.23 65.23 0 005.42-26.13zM165.66 75.3a174.54 174.54 0 01-18.83 5.7 125.34 125.34 0 013.06 21.39h25.48a65 65 0 00-9.71-27.09zm-34-26.83a57.2 57.2 0 017.5 11.25 85.69 85.69 0 015.69 14A167.34 167.34 0 00161.06 69a65.61 65.61 0 00-29.4-20.48zM60 68.62a156.25 156.25 0 0016 4.93 86.15 86.15 0 015.63-13.83 57.64 57.64 0 017.5-11.25 65.49 65.49 0 00-25 15.64Q62 66.27 60 68.62zm-14.58 33.8H70.9a126.48 126.48 0 013.1-21.6 167.23 167.23 0 01-18.64-5.89 65 65 0 00-9.94 27.49zm5.23 34.76q11-3 21.79-5a132.33 132.33 0 01-1.77-21.76V110H44.94v.44a65.21 65.21 0 005.71 26.74z" />
        </g>
      </g>
    </svg>
  )
}
