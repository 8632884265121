import React from 'react'
import styled from 'styled-components'
import { InLink } from '../../atoms/ui/links'
import { device } from '../../GlobalStyles/helpers/device'

const Container = styled.ul`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
  padding: 15px;
  text-align: center;

  @media ${device.tablet} {
    text-align: left;
  }
`

interface Props {
  className?: string
  links: Array<Link>
}

interface Link {
  to: string
  value: string
}

const Item = (link: Link): JSX.Element => (
  <li key={link.value + Math.random().toString}>
    <InLink to={link.to}>{link.value}</InLink>
  </li>
)

export const SiteMap = ({ links, className }: Props): JSX.Element => {
  return <Container className={className}>{links.map((link) => Item(link))}</Container>
}
