import React from 'react'

export const ServiceIcon = (): JSX.Element => {
  return (
    <svg id="prefix__Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <path
        className="prefix__cls-1"
        d="M92.39 254.91v63.59h.07c.29 7.05 4.4 15.39 11.48 24l9.59-77.43zM250 326.66a42.24 42.24 0 01-15.87-3.49l-89-42.83 12.61 101.57A213.94 213.94 0 00249 402.8c85.69 0 155.28-54.07 156.54-84.29h.09v-62.8l-139.71 67.4a41.92 41.92 0 01-15.92 3.55zM110.16 411.71s3.21 15.52 18.28 15.52 18.27-15.52 18.27-15.52l-18.27-139.33z"
      />
      <path
        className="prefix__cls-1"
        d="M493.57 184.54l-228-108.7c-8.58-4.09-22.62-4.09-31.2 0L6.43 184.49c-8.57 4.1-8.57 10.8 0 14.89l99.67 47.5c4.43 2.11 8.44 3.89 11.32 5 2.69 1.09 4.44 2.19 4.44 1.91a6.44 6.44 0 012.1-5c.39-.32 2.17-1.34 4.71-2.73l14.71-7.65 80-41.58a11 11 0 01-1.19-4.73c0-9.59 12.43-17.35 27.78-17.35s27.77 7.77 27.77 17.35-12.42 17.39-27.74 17.39a40.7 40.7 0 01-13-2.11l-96.68 50.24a3.61 3.61 0 00-1.4 2.62 3.39 3.39 0 00.86 2.44c3.58 2 7.22 3.74 12.08 6.09l82.52 39.32c8.58 4.12 22.61 4.12 31.19 0l228-108.66c8.57-4.11 8.57-10.8 0-14.89z"
      />
    </svg>
  )
}
